import React from "react"
import { graphql } from "gatsby"

import Layout from "~components/layout"
import SEO from "~components/seo"
import styled from "styled-components"

import theme, { ArticleWrapper, ArticleList } from "../theme/common"
import useBreakpoint from '~utils/use-breakpoint'
import Article from "~components/Article"
import Sidebar from "~components/Sidebar"

const ArticlePage = ({ pageContext, location }) => {
  const { sm } = useBreakpoint()

  const { id, title, slug, content } = pageContext

  return (
    <Layout>
      <SEO
        title={title}
        description={content?.seo?.metaDescription}
        image={content?.seo?.socialImage?.asset?.url}
        />
      <ArticleWrapper style={{marginTop: '-1rem'}}>
        {sm && <ArticleList>
          <Sidebar location={location} />
        </ArticleList>}
        <Article
          id={id}
          title={title}
          slug={slug}
          content={content}
          location={location}
        />
      </ArticleWrapper>
    </Layout>
  )
}

export default ArticlePage
